import React, { useEffect, useRef } from "react";
import "./Benefits.css";

function Card({ title, body }) {
  return (
    <div style={{ width: "240px" }}>
      <div className="my-card p-3">
        <p className="card-title">{title}</p>
        <div className="accent-line" style={{ width: "38px" }} />
        <p className="card-text mt-3" style={{ fontSize: "19px" }}>
          {body}
        </p>
      </div>
    </div>
  );
}

const Cards = [
  {
    title: (
      <span>
        ACESSO
        <br />
        RÁPIDO
      </span>
    ),
    body: "Capital de giro para financiar produção e operações.",
  },
  {
    title: "OPORTUNIDADE DE CRESCIMENTO",
    body: "Maior flexibilidade financeira.",
  },
  {
    title: (
      <span>
        POSIÇÃO
        <br />
        COMPETITIVA
      </span>
    ),
    body: "Mantendo uma base financeira sólida.",
  },
  {
    title: (
      <span>
        MAXIMIZE
        <br />
        LUCROS
      </span>
    ),
    body: "Libere capital em contas a receber, transformando em liquidez imediata.",
  },
];

export default function Benefits() {
  const swiperRef = useRef();

  useEffect(() => {
    const initializeSwiper = () => {
      if (window.Swiper) {
        new window.Swiper(".swiper-container", {
          slidesPerView: 4,
          speed: 500,
          spaceBetween: 33,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".benefits-next",
            prevEl: ".benefits-prev",
          },
          breakpoints: {
            320: {
              slidesPerView: 1.33,
              spaceBetween: 0,
            },
            470: {
              slidesPerView: 1.33,
              spaceBetween: 32,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3.33,
              spaceBetween: 24,
            },
            1250: {
              slidesPerView: 4,
              spaceBetween: 32,
            },
          },
        });
      } else {
        setTimeout(initializeSwiper, 100);
      }
    };

    initializeSwiper();
  }, []);
  return (
    <div className="container  pb-5" id="benefits">
      <div className="row justify-content-center">
        <div className="section mt-5 mb-5">
          <div className="line" />
          <h3>Vantagens</h3>
          <div className="line" />
        </div>

        <div
          className="row benefits-container"
          style={{ gap: "20px", justifyContent: "center" }}
        >
          {Cards.map((item) => (
            <Card title={item.title} body={item.body} key={item.title} />
          ))}
        </div>
        <div className="carousel-container">
          <div className="swiper">
            <div className="swiper-container">
              <div className="swiper-wrapper">
                {Cards.map((item, index) => {
                  return (
                    <div className="swiper-slide" key={index}>
                      <Card
                        title={item.title}
                        body={item.body}
                        key={item.title}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="carousel-button-container">
            <button
              className="carousel-button prev-button benefits-prev"
              onClick={() => {
                swiperRef.current?.slidePrev();
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 12H19"
                  stroke="#383838"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 5L19 12L12 19"
                  stroke="#383838"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>

            <button
              className="carousel-button next-button benefits-next"
              onClick={() => {
                swiperRef.current?.slideNext();
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 12H19"
                  stroke="#383838"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 5L19 12L12 19"
                  stroke="#383838"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
