import React from "react";
import "./OurNumbers.css";
const OurNumbers = () => {
  return (
    <div className="accent-bg" id="about" style={{ position: "relative" }}>
      <div className="container">
        <section className="our-numbers">
          <h2>Nossos números:</h2>
          <div className="numbers-container">
            <div className="number-item">
              <div className="number">+2.6 Bi</div>
              <div className="description">Antecipações Realizadas</div>
            </div>
            <div className="number-item">
              <div className="number">
                895 <span>fornecedores</span>
              </div>
              <div className="description">Parceiros</div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default OurNumbers;
