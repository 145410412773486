/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from "@material-ui/core";
import { Modal } from "antd";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";

import ModalPrivacyPolicyContent from "./PrivacyPolicyModalContent";
import { getUrl } from "../utils/getUrl";

export default function HeaderReceivablesAnticipation() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const history = useHistory();
  const handleLinkClick = (path) => {
    if (
      history.location.pathname === "/faq" ||
      history.location.pathname === "/antecipacao-recebiveis"
    ) {
      history.push("/");
      setTimeout(() => {
        const section = document.getElementById(path.replace("#", ""));
        if (section) {
          const offset = section.getBoundingClientRect().top - 180; // Defina o valor de deslocamento negativo para rolar um pouco acima da seção
          window.scrollTo({
            top: offset,
            behavior: "smooth",
          });
        }
      }, 200);
    } else {
      history.push(path);
    }
  };

  const url = getUrl();

  return (
    <nav className="navbar navbar-expand-lg fixed-top navbar-dark primary-bg shadow header">
      <div className="container pl-3 pr-3">
        <Helmet>
          <meta charSet="utf-8" />
          <script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            data-domain-script="65a6e80e-2c46-46e1-a780-140b847b984e"
          />
          <script type="text/javascript">
            {/* function OptanonWrapper() { }  */}
          </script>
        </Helmet>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo03"
          aria-controls="navbarTogglerDemo03"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <a className="logo" onClick={() => handleLinkClick("#about")}>
          <img
            className="header-logo"
            src="/assets/images/logo.png"
            alt="logo Alesta"
          />
        </a>

        <div className="button-mobile">
          <Button
            variant="contained"
            size="large"
            color="primary"
            className="btn-simulate"
            style={{ backgroundColor: "#162655" }}
            href={`http://portalcorp.cpfl.com.br/irj/portal/`}
            target="_blank"
          >
            <span
              style={{
                color: "#c9da2a",
                textTransform: "capitalize",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              Portal Fornecedor
              <img
                src="/assets/svgs/antecipacao/cta-button.svg"
                alt="arrow right"
                style={{ width: "24px", height: "24px" }}
              />
            </span>
          </Button>
        </div>

        <div
          className="collapse navbar-collapse custom-navbar"
          id="navbarTogglerDemo03"
        >
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a
                activeClass="link-style-selected"
                className="link-style"
                onClick={() => handleLinkClick("#about-us")}
              >
                Sobre nós
              </a>
            </li>
            <li className="nav-item">
              <div className="dropdown">
                <div className="dropbtn">Financiamento em conta</div>
                <div className="dropdown-content">
                  <a
                    activeClass="link-style-selected"
                    className="link-style"
                    onClick={() => handleLinkClick("#benefits")}
                  >
                    Vantagens
                  </a>
                  <a
                    activeClass="link-style-selected"
                    className="link-style"
                    onClick={() => handleLinkClick("#how")}
                  >
                    Como funciona
                  </a>
                  <Link
                    activeClass="link-style-selected"
                    className="link-style"
                    to="/faq"
                  >
                    Dúvidas
                  </Link>
                </div>
              </div>
            </li>

            <li className="nav-item">
              <div className="dropdown">
                <div className="dropbtn">Para sua empresa</div>
                <div className="dropdown-content">
                  <Link
                    activeClass="link-style-selected"
                    className="link-style"
                    to="/antecipacao-recebiveis"
                  >
                    Antecipação de recebiveis
                  </Link>
                </div>
              </div>
            </li>

            <li className="nav-item">
              <a
                activeClass="link-style-selected"
                className="link-style"
                onClick={() => handleLinkClick("#contact")}
              >
                Fale com a gente
              </a>
            </li>
          </ul>
        </div>

        <div className="button-desktop">
          <Button
            variant="contained"
            size="large"
            color="primary"
            className="btn-simulate"
            style={{ backgroundColor: "#162655" }}
            href={`http://portalcorp.cpfl.com.br/irj/portal/`}
            target="_blank"
          >
            <span
              style={{
                color: "#c9da2a",
                textTransform: "capitalize",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              Portal Fornecedor
              <img
                src="/assets/svgs/antecipacao/cta-button.svg"
                alt="arrow right"
                style={{ width: "24px", height: "24px" }}
              />
            </span>
          </Button>
        </div>
      </div>

      <p className="tiny-text-header">
        Antecipações até as 11h são pagas no mesmo dia.
      </p>

      <div className="container_modal">
        <Modal
          title="Aviso de Privacidade"
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered="true"
          style={{
            height: "calc(100vh - 200px)",
            top: 40,
          }}
          bodyStyle={{ overflowY: "scroll" }}
          width={1200}
        >
          <ModalPrivacyPolicyContent />
        </Modal>
      </div>
    </nav>
  );
}
