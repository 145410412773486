import React from "react";
import "./Contact.css";

export default function Contact() {

  return (
    <>
      <div className="container mt-5" id="contact">
        <div className="row justify-content-center">
          <div className="col-auto">
            <div className="row justify-content-center">
              <div className="section">
                <div className="line" />
                <h3>Dúvidas?</h3>
                <div className="line" />
              </div>
            </div>
            <h4>
              Ainda ficou com alguma dúvida? Sem problemas, <br />a gente
              esclarece <span className="highlight-accent">para você.</span>
            </h4>
          </div>
        </div>
      </div>
      <div id="about" style={{background: "#f0f4f7" }}>
        <div className="container">
          <div class="contact-info">
            <div class="contact-item">
              <img
                src="/assets/svgs/antecipacao/email.svg"
                alt="Email Icon"
                class="icon"
              />
              <span>antecipacao@cpfl.com.br</span>
            </div>
            <div class="contact-item">
              <img
                src="/assets/svgs/antecipacao/calendar.svg"
                alt="Calendar Icon"
                class="icon"
              />
              <span>
                Horário de atendimento <br /> 08h00 até às 17h00
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
