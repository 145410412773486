import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./containers/Home";
import Negociate from "./containers/Negociate";
import FaqPage from "./containers/Faq";
import ReceivablesAnticipationPage from "./containers/ReceivablesAnticipation";

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/negocie">
          <Negociate />
        </Route>
        <Route path="/faq">
          <FaqPage />
        </Route>
        <Route path="/antecipacao-recebiveis">
          <ReceivablesAnticipationPage />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}
