import React, { useEffect } from "react";
import Footer from "../components/Footer";

import Banner from "../components/ReceivablesAnticipation/Banner";
import OurNumbers from "../components/ReceivablesAnticipation/OurNumbers";
import Contact from "../components/ReceivablesAnticipation/Contact";
import HeaderReceivablesAnticipation from "../components/HeaderReceivablesAnticipation";
import Timeline from "../components/ReceivablesAnticipation/Timeline";
import Benefits from "../components/ReceivablesAnticipation/Benefits";
import { animateScroll as scroll } from "react-scroll";

export default function ReceivablesAnticipation() {
  useEffect(() => {
    const hideOctadesk = () => {
      const octadeskApp = document.getElementById("octadesk-octachat-appchat")
      if (octadeskApp) {
        octadeskApp.style.display = "none";
      }
    };
  
    hideOctadesk();
    scroll.scrollToTop();
  
    return () => {
      const showOctadesk = () => {
        const octadeskApp = document.getElementById("octadesk-octachat-appchat");
        if (octadeskApp) {
          octadeskApp.style.display = "block";
        }
      };
  
      showOctadesk();
    };
  }, []);
  
  return (
    <>
      <HeaderReceivablesAnticipation />
      <main>
        <Banner />
        <Benefits />
        <OurNumbers />
        <Timeline />
        <Contact />
      </main>
      <Footer />
    </>
  );
}
