import React, { useEffect, useRef } from "react";
import "./Timeline.css";

export default function Timeline() {
  const swiperRef = useRef();

  useEffect(() => {
    const initializeSwiper = () => {
      if (window.Swiper) {
        new window.Swiper(".timeline-swiper", {
          slidesPerView: 4,
          speed: 500,
          spaceBetween: 33,
          pagination: {
            el: ".timeline-swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".timeline-next",
            prevEl: ".timeline-prev",
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 2.3,
              spaceBetween: 10,
            },
            1250: {
              slidesPerView: 4,
              spaceBetween: 20,
          },
        }
        });
      } else {
        setTimeout(initializeSwiper, 100);
      }
    };

    initializeSwiper();
  }, []);

  return (
    <div className="primary-bg" id="howItWorks">
      <div className="container" id="benefits">
        <div className="row justify-content-center pb-5">
          <div className="section mt-5 mb-3">
            <h3 style={{color: "#162655"}}>Como funciona a Antecipação de Recebíveis?</h3>
          </div>
            <h4 style={{color: "#FFF", fontSize: "1.625rem", fontWeight: 600}}>É fácil, rápido e 100% digital</h4>

          <div className="timeline-container">
            <div className="swiper swiper-timeline">
              <div className="timeline-swiper">
                <div className="swiper-wrapper">
                  <div class="swiper-slide swiper-slide-timeline">
                    <div class="swiper-slide__marker">1</div>
                    <div class="swiper-slide__content">
                      <div
                        class="swiper-slide__top-side"
                        style={{ top: 70, left: 20 }}
                      >
                        <div class="swiper-slide__card">
                          <img
                            src="/assets/svgs/antecipacao/timeline-1.svg"
                            alt=""
                            style={{ width: 160 }}
                          />
                        </div>
                      </div>
                      <div class="swiper-slide__bottom-side" >
                        <div class="swiper-slide__card">
                          A partir de 2022, as intermediações das antecipações{" "}
                          <span style={{ fontWeight: 600, color: "#c9da2a" }}>
                            são realizadas pela Alesta
                          </span>
                          , a Fintech/financeira do Grupo CPFL.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="swiper-slide swiper-slide-timeline">
                    <div class="swiper-slide__marker">2</div>
                    <div class="swiper-slide__content">
                      <div class="swiper-slide__top-side">
                        <div class="swiper-slide__card">
                          A operação é feita pelo fornecedor através do Portal
                          Financeiro de Fornecedores (PFF),
                          <strong style={{ fontWeight: 600, color: "#162655" }}>
                            {" "}
                            de maneira fácil, ágil e transparente.
                          </strong>
                        </div>
                      </div>
                      <div
                        class="swiper-slide__bottom-side"
                        style={{ bottom: 40, left: 0 }}
                      >
                        <div class="swiper-slide__card">
                          <img
                            src="/assets/svgs/antecipacao/timeline-2.svg"
                            alt=""
                            style={{ width: 160, height: 124 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="swiper-slide swiper-slide-timeline">
                    <div class="swiper-slide__marker">3</div>
                    <div class="swiper-slide__content">
                      <div
                        class="swiper-slide__top-side"
                        style={{ top: 40, left: 28 }}
                      >
                        <div class="swiper-slide__card">
                          <img
                            src="/assets/svgs/antecipacao/timeline-3.svg"
                            alt=""
                            style={{ width: 160, height: 102 }}
                          />
                        </div>
                      </div>
                      <div class="swiper-slide__bottom-side" style={{ bottom: 50 }}>
                        <div class="swiper-slide__card">
                          É possível escolher{" "}
                          <strong style={{ fontWeight: 600, color: "#c9da2a" }}>
                            uma ou mais notas
                          </strong>
                          , bem como a data para receber.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="swiper-slide swiper-slide-timeline">
                    <div class="swiper-slide__marker">4</div>
                    <div class="swiper-slide__content">
                      <div class="swiper-slide__top-side" style={{top: 48}}>
                        <div class="swiper-slide__card">
                          Antecipações{" "}
                          <strong style={{ fontWeight: 600, color: "#c9da2a" }}>
                            solicitadas no portal até as 11h
                          </strong>{" "}
                          da manhã, serão{" "}
                          <strong style={{ fontWeight: 600, color: "#162655" }}>
                            pagas no mesmo dia.
                          </strong>
                        </div>
                      </div>
                      <div
                        class="swiper-slide__bottom-side"
                        style={{ bottom: 50, left: 46 }}
                      >
                        <div class="swiper-slide__card">
                          <img
                            src="/assets/svgs/antecipacao/timeline-4.svg"
                            alt=""
                            style={{ width: 100 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="timeline-swiper-pagination"></div>
            </div>

            <div className="carousel-button-container timeline-buttons">
              <button
                className="carousel-button prev-button timeline-prev"
                onClick={() => swiperRef.current?.slidePrev()}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="#383838"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 5L19 12L12 19"
                    stroke="#383838"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>

              <button
                className="carousel-button next-button timeline-next"
                onClick={() => swiperRef.current?.slideNext()}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="#383838"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 5L19 12L12 19"
                    stroke="#383838"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
