import React from "react";
import "../PrimarySection.css";
import Button from "@material-ui/core/Button";
import { getUrl } from "../../utils/getUrl";
import { Link } from "react-scroll";

export default function Banner() {
  const url = getUrl();
  return (
    <div className="primary-bg" id="about" style={{ position: "relative" }}>
      <div className="container">
        <img
          src="assets/images/banner-antecipacao-recebiveis.png"
          alt=""
          className="rounded banner"
          style={{ top: "0", height: "100%" }}
        />
        <div className="row">
          <div
            className="col-md-12 pl-5"
            style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
          >
            <div className="h-100 d-flex flex-column justify-content-center align-items-start">
              <h1 className="title" style={{ maxWidth: "626px" }}>
                <span className="highlight-accent">
                  Antecipe seus recebíveis com as melhores taxas
                </span>
              </h1>
              <h2
                className="subtitle"
                style={{
                  color: "#162655",
                  fontWeight: "400",
                  fontSize: "26px",
                }}
              >
                para impulsionar as finanças do seu negócio.
              </h2>
              <Button
                variant="contained"
                size="large"
                color="primary"
                className="btn-simulate mt-5"
              >
                <Link
                  to="howItWorks"
                  spy={true}
                  smooth={true}
                  offset={-130}
                  duration={500}
                >
                  veja como
                </Link>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
